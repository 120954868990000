import Logo from "@livepix/components/common/Logo";
import styled from "@emotion/styled";

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Content = styled.div`
  max-width: 600px;
  padding: 50px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
`;

const LogoContainer = styled.div`
  max-width: 200px;
`;

const Title = styled.p`
  margin: 20px 0 0;
  font-weight: 600;
  font-size: 36px;
`;

const Message = styled.p`
  font-size: 22px;
  color: #333;
`;

export default function DeprecatedWarning() {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Title>Ooops!</Title>
        <Message>
          Você está usando uma versão descontinuada dos widgets. Por favor, acesse seu dashboard e clique no menu{" "}
          <b>Widgets</b> para começar a usar a nova versão.
        </Message>
      </Content>
    </Container>
  );
}
