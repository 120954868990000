import React from "react";
import DeprecatedWarning from "components/widget/DeprecatedWarning";

export default function Qrcode() {
  return (
    <>
      <style jsx global>{`
        body {
          background: transparent !important;
        }
      `}</style>
      <DeprecatedWarning />
    </>
  );
}
